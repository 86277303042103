<template>
  <div>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                        <h3>
                            <b>Detail</b>
                        </h3>
                        <hr>
                        <NewTreeView class="item" :model="treeView"/>
                    </div>
                  </div>
                </div>
              </div>

        </div>
        <!-- <h3>{{ response_dispo }}</h3> -->
      </div>
    </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs,
    TreeView,
    Header
  },
  data() {
    return {
        treeView:
        {
            "status": "Belum dikonfirmasi",
            "satker": "MENTERI LUAR NEGERI",
            "profil": "Admin",
            "position": "MENTERI LUAR NEGERI",
            "name": "MENTERI LUAR NEGERI",
            "jabatan": "MENTERI LUAR NEGERI",
            "description": "",
            "children": [
                {
                    "name": "WAKIL MENTERI LUAR NEGERI",
                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                    "position": "WAKIL MENTERI LUAR NEGERI",
                    "status": "Belum dibaca ",
                    "description": "-",
                    "sender_unit": "MENTERI LUAR NEGERI",
                    "profil": "Am. Fachir",
                    "children": [
                        {
                            "name": "MENTERI LUAR NEGERI",
                            "satker": "MENTERI LUAR NEGERI",
                            "jabatan": "MENTERI LUAR NEGERI",
                            "position": "MENTERI LUAR NEGERI",
                            "profil": "Admin",
                            "status": "Belum dikonfirmasi",
                            "description": "",
                            "children": [
                                {
                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                    "status": "Belum dibaca ",
                                    "description": "-",
                                    "sender_unit": "MENTERI LUAR NEGERI",
                                    "profil": "Am. Fachir",
                                    "children": [
                                        {
                                            "name": "MENTERI LUAR NEGERI",
                                            "satker": "MENTERI LUAR NEGERI",
                                            "jabatan": "MENTERI LUAR NEGERI",
                                            "position": "MENTERI LUAR NEGERI",
                                            "profil": "Admin",
                                            "status": "Belum dikonfirmasi",
                                            "description": "",
                                            "children": [
                                                {
                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                    "status": "Belum dibaca ",
                                                    "description": "-",
                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                    "profil": "Am. Fachir",
                                                    "children": [
                                                        {
                                                            "name": "MENTERI LUAR NEGERI",
                                                            "satker": "MENTERI LUAR NEGERI",
                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                            "position": "MENTERI LUAR NEGERI",
                                                            "profil": "Admin",
                                                            "status": "Belum dikonfirmasi",
                                                            "description": "",
                                                            "children": [
                                                                {
                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                    "status": "Belum dibaca ",
                                                                    "description": "-",
                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                    "profil": "Am. Fachir",
                                                                    "children": [
                                                                        {
                                                                            "name": "MENTERI LUAR NEGERI",
                                                                            "satker": "MENTERI LUAR NEGERI",
                                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                                            "position": "MENTERI LUAR NEGERI",
                                                                            "profil": "Admin",
                                                                            "status": "Belum dikonfirmasi",
                                                                            "description": "",
                                                                            "children": [
                                                                                {
                                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                                    "status": "Belum dibaca ",
                                                                                    "description": "-",
                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                    "profil": "Am. Fachir",
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "MENTERI LUAR NEGERI",
                                                                                            "satker": "MENTERI LUAR NEGERI",
                                                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                                                            "position": "MENTERI LUAR NEGERI",
                                                                                            "profil": "Admin",
                                                                                            "status": "Belum dikonfirmasi",
                                                                                            "description": "",
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                                                    "status": "Belum dibaca ",
                                                                                                    "description": "-",
                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                    "profil": "Am. Fachir",
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "MENTERI LUAR NEGERI",
                                                                                                            "satker": "MENTERI LUAR NEGERI",
                                                                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                                                                            "position": "MENTERI LUAR NEGERI",
                                                                                                            "profil": "Admin",
                                                                                                            "status": "Belum dikonfirmasi",
                                                                                                            "description": "",
                                                                                                            "children": [
                                                                                                                {
                                                                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                    "status": "Belum dibaca ",
                                                                                                                    "description": "-",
                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                    "profil": "Am. Fachir",
                                                                                                                    "children": [
                                                                                                                        {
                                                                                                                            "name": "MENTERI LUAR NEGERI",
                                                                                                                            "satker": "MENTERI LUAR NEGERI",
                                                                                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                                                                                            "position": "MENTERI LUAR NEGERI",
                                                                                                                            "profil": "Admin",
                                                                                                                            "status": "Belum dikonfirmasi",
                                                                                                                            "description": "",
                                                                                                                            "children": [
                                                                                                                                {
                                                                                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                    "description": "-",
                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                    "profil": "Am. Fachir",
                                                                                                                                    "children": [
                                                                                                                                        {
                                                                                                                                            "name": "MENTERI LUAR NEGERI",
                                                                                                                                            "satker": "MENTERI LUAR NEGERI",
                                                                                                                                            "jabatan": "MENTERI LUAR NEGERI",
                                                                                                                                            "position": "MENTERI LUAR NEGERI",
                                                                                                                                            "profil": "Admin",
                                                                                                                                            "status": "Belum dikonfirmasi",
                                                                                                                                            "description": "",
                                                                                                                                            "children": [
                                                                                                                                                {
                                                                                                                                                    "name": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                                    "jabatan": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                                    "position": "WAKIL MENTERI LUAR NEGERI",
                                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                                    "description": "-",
                                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                                    "profil": "Am. Fachir",
                                                                                                                                                    "children": []
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                                    "description": "-",
                                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                                    "profil": "",
                                                                                                                                                    "children": []
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                                    "description": "-",
                                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                                    "profil": "",
                                                                                                                                                    "children": []
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                                    "description": "-",
                                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                                    "profil": "",
                                                                                                                                                    "children": []
                                                                                                                                                }
                                                                                                                                            ]
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                    "description": "-",
                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                    "profil": "",
                                                                                                                                    "children": []
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                    "description": "-",
                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                    "profil": "",
                                                                                                                                    "children": []
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                                    "status": "Belum dibaca ",
                                                                                                                                    "description": "-",
                                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                                    "profil": "",
                                                                                                                                    "children": []
                                                                                                                                }
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    ]
                                                                                                                },
                                                                                                                {
                                                                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                                    "status": "Belum dibaca ",
                                                                                                                    "description": "-",
                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                    "profil": "",
                                                                                                                    "children": []
                                                                                                                },
                                                                                                                {
                                                                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                                    "status": "Belum dibaca ",
                                                                                                                    "description": "-",
                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                    "profil": "",
                                                                                                                    "children": []
                                                                                                                },
                                                                                                                {
                                                                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                                    "status": "Belum dibaca ",
                                                                                                                    "description": "-",
                                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                                    "profil": "",
                                                                                                                    "children": []
                                                                                                                }
                                                                                                            ]
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                                    "status": "Belum dibaca ",
                                                                                                    "description": "-",
                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                    "profil": "",
                                                                                                    "children": []
                                                                                                },
                                                                                                {
                                                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                                    "status": "Belum dibaca ",
                                                                                                    "description": "-",
                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                    "profil": "",
                                                                                                    "children": []
                                                                                                },
                                                                                                {
                                                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                                    "status": "Belum dibaca ",
                                                                                                    "description": "-",
                                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                                    "profil": "",
                                                                                                    "children": []
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                                    "status": "Belum dibaca ",
                                                                                    "description": "-",
                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                    "profil": "",
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                                    "status": "Belum dibaca ",
                                                                                    "description": "-",
                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                    "profil": "",
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                                    "status": "Belum dibaca ",
                                                                                    "description": "-",
                                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                                    "profil": "",
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                                    "status": "Belum dibaca ",
                                                                    "description": "-",
                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                    "profil": "",
                                                                    "children": []
                                                                },
                                                                {
                                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                                    "status": "Belum dibaca ",
                                                                    "description": "-",
                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                    "profil": "",
                                                                    "children": []
                                                                },
                                                                {
                                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                                    "status": "Belum dibaca ",
                                                                    "description": "-",
                                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                                    "profil": "",
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                                    "status": "Belum dibaca ",
                                                    "description": "-",
                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                    "profil": "",
                                                    "children": []
                                                },
                                                {
                                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                                    "status": "Belum dibaca ",
                                                    "description": "-",
                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                    "profil": "",
                                                    "children": []
                                                },
                                                {
                                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                                    "status": "Belum dibaca ",
                                                    "description": "-",
                                                    "sender_unit": "MENTERI LUAR NEGERI",
                                                    "profil": "",
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                                    "status": "Belum dibaca ",
                                    "description": "-",
                                    "sender_unit": "MENTERI LUAR NEGERI",
                                    "profil": "",
                                    "children": []
                                },
                                {
                                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                                    "status": "Belum dibaca ",
                                    "description": "-",
                                    "sender_unit": "MENTERI LUAR NEGERI",
                                    "profil": "",
                                    "children": []
                                },
                                {
                                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                                    "status": "Belum dibaca ",
                                    "description": "-",
                                    "sender_unit": "MENTERI LUAR NEGERI",
                                    "profil": "",
                                    "children": []
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                    "jabatan": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                    "position": "DIREKTORAT JENDERAL ASIA PASIFIK DAN AFRIKA",
                    "status": "Belum dibaca ",
                    "description": "-",
                    "sender_unit": "MENTERI LUAR NEGERI",
                    "profil": "",
                    "children": []
                },
                {
                    "name": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                    "jabatan": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                    "position": "DIREKTORAT JENDERAL KERJASAMA ASEAN",
                    "status": "Belum dibaca ",
                    "description": "-",
                    "sender_unit": "MENTERI LUAR NEGERI",
                    "profil": "",
                    "children": []
                },
                {
                    "name": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                    "jabatan": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                    "position": "DIREKTORAT JENDERAL HUKUM DAN PERJANJIAN INTERNASIONAL",
                    "status": "Belum dibaca ",
                    "description": "-",
                    "sender_unit": "MENTERI LUAR NEGERI",
                    "profil": "",
                    "children": []
                }
            ]
        }
    };
  }
};
</script>
